<template>

    <div class="h-full flex flex-col overflow-hidden relative">

        <div class="h-full w-full rounded-lg module p-2 flex flex-col">

            <div class="h-10 flex flex-row justify-start items-center py-2 overflow-hidden relative">

                <input type="text" v-model="searchInput" placeholder="Search" class="w-9/12 rounded-md h-full bg-body px-2
                    text-sm text-font-gray">

                <div  v-on:click="setAlertPositiveOrNegative" class=" w-2/12">
                    <div class="h-6 w-6 header-btn">
                        <i v-if="!this.alertPositiveOrNegative" class="text-xxs mdi mdi-thumb-up-outline"></i>
                        <i v-else-if=" this.alertPositiveOrNegative==='positive'" class="text-xxs mdi mdi-thumb-up"></i>
                        <i v-else-if=" this.alertPositiveOrNegative==='negative'" class="text-xxs mdi mdi-thumb-down"></i>
                    </div>
                </div>

                <div class="h-full w-1/12 flex flex-col justify-center items-center" @click="openFilters = !openFilters">
                    <i class="mdi mdi-dots-vertical tex-twhite"></i>
                </div>

            </div>

            <div class="flex-1 overflow-auto no-scrollbar bg-gradient-module pt-2" style="transition: all .3s">

                <div class="h-auto pb-4">

                    <div v-if="!loading_sales && projects_sales && projects_sales.data.length > 0" class="h-auto">

                        <div class="relative" :class="{'h-32': loading_sales || projects_sales.meta.total > 0, 'h-auto': projects_sales.meta.total == 0}">

                            <div v-if="projects_sales" class="px-2 h-auto flex flex-row justify-between items-center">
                                <span class="text-danger font-semibold text-xs">Sales</span>
                                <span class="text-danger font-semibold text-xs">
                                    {{projects_sales.data.length}} / {{ projects_sales.meta.total }}
                                    <i class="mdi mdi-alert text-xs"></i>
                                </span>
                            </div>

                            <div v-if="projects_sales" class="h-auto mt-2 flex flex-row overflow-x-auto no-scrollbar p-2">

                                <div v-for="(el, index) in searchProject(projects_sales.data)" :key="index" class="h-full w-64 flex-none -mr-2">

                                    <AlertProject view="mobile" type="danger" @openProject="openProject"
                                            :alert="el" />

                                </div>

                                <div v-if="projects_sales.data.length != projects_sales.meta.total" class="h-full w-64 flex-none relative">

                                    <loader :loading="loading_sales"/>

                                    <div class="h-24 w-full flex flex-row mr-auto pb-2 rounded border-2 border-font-gray bg-font-gray-15 flex flex-col justify-center items-center shadow-md" @click="page_projects_sales++">

                                        <i class="mdi mdi-plus text-font-gray text-3xl"></i>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else-if="loading_sales" class="h-32 relative">
                        <loader :loading="loading_sales" />
                    </div>

                </div>

                <div class="h-auto pb-4">

                    <div v-if="!loading_margin && projects_margin && projects_margin.data.length > 0" class="h-auto">

                        <div class="relative" :class="{'h-32': loading_margin || projects_margin.meta.total > 0, 'h-auto': projects_margin.meta.total == 0}">
                            
                            <div v-if="projects_margin" class="px-2 h-auto flex flex-row justify-between items-center">

                                <div class="h-full flex flex-1 min-w-0 flex flex-row justify-start items-center">
                                    <span class="text-danger font-semibold text-xs">Initial Margin vs Current Margin</span>
                                </div>

                                <div class="h-full w-auto ml-2 flex flex-row justify-end items-center">
                                    <span class="text-danger font-semibold text-xs">
                                        {{projects_margin.data.length}} / {{ projects_margin.meta.total }}
                                        <i class="mdi mdi-alert text-xs"></i>
                                    </span>
                                </div>
                                
                            </div>
                            
                            <div v-if="projects_margin" class="h-auto mt-2 flex flex-row overflow-x-auto no-scrollbar p-2">

                                <div v-for="(el, index) in searchProject(projects_margin.data)" :key="index" class="h-full w-64 flex-none -mr-2">

                                    <AlertProject view="mobile" type="danger" @openProject="openProject"
                                            :alert="el" />

                                </div>

                                <div v-if="projects_margin.data.length != projects_margin.meta.total" class="h-full w-64 flex-none relative">

                                    <loader :loading="loading_margin"/>

                                    <div class="h-24 w-full flex flex-row mr-auto pb-2 rounded border-2 border-font-gray bg-font-gray-15 flex flex-col justify-center items-center shadow-md" @click="page_projects_margin++">

                                        <i class="mdi mdi-plus text-font-gray text-3xl"></i>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else-if="loading_margin" class="h-32 relative">
                        <loader :loading="loading_margin" />
                    </div>

                </div>

                <div class="h-auto pb-4">

                    <div v-if="! loading_billing && projects_billing && projects_billing.data.length > 0" class="h-auto">

                        <div class="relative" :class="{'h-32': loading_billing || projects_billing.meta.total > 0, 'h-auto': projects_billing.meta.total == 0}">

                            <div v-if="projects_billing" class="px-2 h-auto flex flex-row justify-between items-center">
                                <span class="text-danger font-semibold text-xs">Billing</span>
                                <span class="text-danger font-semibold text-xs">
                                    {{projects_billing.data.length}} / {{ projects_billing.meta.total }}
                                    <i class="mdi mdi-alert text-xs"></i>
                                </span>
                            </div>

                            <div v-if="projects_billing" class="h-auto mt-2 flex flex-row overflow-x-auto no-scrollbar p-2">

                                <div v-for="(el, index) in searchProject(projects_billing.data)" :key="index" class="h-full w-64 flex-none -mr-2">

                                    <AlertProject view="mobile" type="danger" @openProject="openProject"
                                            :alert="el" />

                                </div>

                                <div v-if="projects_billing.data.length != projects_billing.meta.total" class="h-full w-64 flex-none relative">

                                    <loader :loading="loading_billing"/>

                                    <div class="h-24 w-full flex flex-row mr-auto pb-2 rounded border-2 border-font-gray bg-font-gray-15 flex flex-col justify-center items-center shadow-md" @click="page_projects_billing++">

                                        <i class="mdi mdi-plus text-font-gray text-3xl"></i>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else-if="loading_billing" class="h-32 relative">
                        <loader :loading="loading_billing" />
                    </div>

                </div>

                <div class="h-auto pb-4">

                    <div v-if="!loading_revenue_days && projects_revenue_days && projects_revenue_days.data.length > 0" class="h-auto">

                        <div class="relative" :class="{'h-32': loading_billing || projects_revenue_days.meta.total > 0, 'h-auto': projects_revenue_days.meta.total == 0}">

                            <div v-if="projects_revenue_days" class="px-2 h-auto flex flex-row justify-between items-center">
                                <span class="text-danger font-semibold text-xs">Revenue Days</span>
                                <span class="text-danger font-semibold text-xs">
                                    {{projects_revenue_days.data.length}} / {{ projects_revenue_days.meta.total }}
                                    <i class="mdi mdi-alert text-xs"></i>
                                </span>
                            </div>

                            <div v-if="projects_revenue_days" class="h-auto mt-2 flex flex-row overflow-x-auto no-scrollbar p-2">

                                <div v-for="(el, index) in searchProject(projects_revenue_days.data)" :key="index" class="h-full w-64 flex-none -mr-2">

                                    <AlertProject view="mobile" type="danger" @openProject="openProject"
                                            :alert="el" />

                                </div>

                                <div v-if="projects_revenue_days.data.length != projects_revenue_days.meta.total" class="h-full w-64 flex-none relative">

                                    <loader :loading="loading_revenue_days"/>

                                    <div class="h-24 w-full flex flex-row mr-auto pb-2 rounded border-2 border-font-gray bg-font-gray-15 flex flex-col justify-center items-center shadow-md" @click="page_projects_revenue_days++">

                                        <i class="mdi mdi-plus text-font-gray text-3xl"></i>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else-if="loading_revenue_days" class="h-32 relative">
                        <loader :loading="loading_revenue_days" />
                    </div>

                </div>

                <div class="h-auto pb-4">

                    <div v-if="!loading_projects && projects && projects.data.length > 0" class="h-auto">

                        <div class="relative" :class="{'h-32': loading_billing || projects.meta.total > 0, 'h-auto': projects.meta.total == 0}">

                            <div v-if="projects" class="px-2 h-auto flex flex-row justify-between items-center">
                                <span class="text-white font-semibold text-xs">Projects</span>
                                <span class="text-white font-semibold text-xs">
                                    {{projects.data.length}} / {{ projects.meta.total }}
                                </span>
                            </div>

                            <div v-if="projects" class="h-auto mt-2 flex flex-row overflow-x-auto no-scrollbar p-2">

                                <div v-for="(el, index) in searchProject(projects.data)" :key="index" class="h-full w-64 flex-none -mr-2">

                                    <Project view="mobile" @openProject="openProject" :data="el" />

                                </div>

                                <div v-if="projects.data.length != projects.meta.total" class="h-full w-64 flex-none relative">

                                    <loader :loading="loading_projects"/>

                                    <div class="h-24 w-full flex flex-row mr-auto pb-2 rounded border-2 border-font-gray bg-font-gray-15 flex flex-col justify-center items-center shadow-md" @click="page_projects++">

                                        <i class="mdi mdi-plus text-font-gray text-3xl"></i>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else-if="loading_projects" class="h-32 relative">
                        <loader :loading="loading_projects" />
                    </div>

                </div>

            </div>

        </div>

        <div class="absolute h-56 w-full rounded-lg z-30 bg-filters shadow bottom-0 p-2" :class="{'mb-2':openFilters, '-mb-56':!openFilters}" style="transition: all .3s">

            <div class="flex-none text-font-gray mb-2 text-sm">Project Status</div>
            <multiselect v-model="projectStatusFiltersSelected" openDirection="bottom"
                :options="projectStatusFilters" label="filter_name"/>

            <div class="h-6 w-6 absolute mt-1 mr-1 top-0 right-0 flex flex-col justify-center items-center" @click="openFilters = !openFilters">
                <i class="mdi mdi-close text-white"></i>
            </div>

        </div>

    </div>

</template>

<script>
import { state } from '@/store';
import axios from "axios";
const AlertProject = () => import('@/components/Desktop/ProjectsManagement/AlertProject.vue')
const Project = () => import('@/components/Desktop/ProjectsManagement/Project.vue')

let cancel;
let cancelRevenueDays;
let cancelProject;
let cancelSales;
let cancelFilters;
const CancelToken = axios.CancelToken;
const CancelTokenProjectsRevenue = axios.CancelToken;
const CancelTokenProject = axios.CancelToken;
const CancelTokenSales = axios.CancelToken;
const CancelTokenFilters = axios.CancelToken;

export default {
    components:{
        AlertProject,
        Project
    },
    data(){
        return{
            openSearch: false,
            projectSelected: null,
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 2,
                centeredSlides: true,
            },
            projects: false,
            projects_margin: false,
            projects_billing: false,
            projects_revenue_days: false,
            projects_sales: false,
            page_projects: 1,
            isLoading: false,
            isLoadingRevenueDays: false,
            isLoadingProject: false,
            isLoadingFilters: false,
            isLoadingSales: false,
            queryRevenueDays: "",
            queryProject: "",
            querySales: "",
            queryFilters: "",
            page_projects_margin: 1,
            page_projects_billing: 1,
            page_projects_revenue_days: 1,
            page_projects_sales: 1,
            resultsRevenueDays: "",
            resultsProject: "",
            resultsSales: "",
            resultsFilters: "",
            loading_projects: false,
            loading_margin: false,
            loading_billing: false,
            loading_revenue_days: false,
            loading_sales: false,
            searchInput: "",
            lastPageSales: 0,
            openFilters: false,
            projectStatusFiltersSelected:null,
            projectStatusFilters:[],
            alertPositiveOrNegative: 'negative',
            paramstatus:null
        }
    },
    methods:{
        preload() {
            this.loading = false
            this.loading_projects = true
            this.loading_sales = true
            this.loading_margin = true
            if (this.alertPositiveOrNegative === 'negative' || this.alertPositiveOrNegative === null) {
                this.loading_revenue_days = true
            }
        },
        clear() {
            this.isLoading = false;
            this.results = "";
            this.query = "";
        },
        clearProjectsRevenueDays() {
            this.isLoadingRevenueDays = false;
            this.resultsRevenueDays = "";
            this.queryRevenueDays = "";
        },
        clearProject() {
            this.isLoadingProject = false;
            this.resultsProject = "";
            this.queryProject = "";
        },
        clearSales() {
            this.isLoadingSales = false;
            this.resultsSales = "";
            this.querySales = "";
        },
        clearFilters() {
            this.isLoadingFilters = false;
            this.resultsFilters = "";
            this.queryFilters = "";
        },
        handleSearch: _.debounce(function() {
            this.preApiCall();
        }, 300),
        handleSearchRevenueDays: _.debounce(function() {
            this.preApiProjectsCallRevenueDays();
        }, 300),
        handleSearchProject: _.debounce(function() {
            this.preApiProjectsCallProject();
        }, 300),
        handleSearchSales: _.debounce(function() {
            this.preApiProjectsSales();
        }, 300),
        handleSearchFilters: _.debounce(function() {
            this.preApiProjectsFilters();
        }, 300),
        preApiProjectsFilters() {
            if (cancelFilters !== undefined) {
                cancelFilters();
            }
            this.queryFilters = {
                ...this.call_params
            }
            this.loadProjectsFilters(this.querySales);
        },
        preApiProjectsSales() {
            if (cancelSales !== undefined) {
                cancelSales();
            }
            this.querySales = {
                ...this.call_params,
                page: this.page_projects_sales
            }
            this.loadProjectsSales(this.querySales);
        },
        preApiProjectsCallRevenueDays() {
            if (cancelRevenueDays !== undefined) {
                cancelRevenueDays();
            }
            this.queryRevenueDays = {
                ...this.call_params,
                page: this.page_projects_revenue_days
            }
            this.loadProjectsRevenueDays(this.queryRevenueDays);
        },
        preApiProjectsCallProject() {
            if (cancelProject !== undefined) {
                cancelProject();
            }
            this.queryProject = {
                ...this.call_params,
                page: this.page_projects
            }
            this.loadProjects(this.queryProject);
        },
        preApiCall() {
            if (cancel !== undefined) {
                cancel();
            }
            this.query = {
                ...this.call_params,
                page: this.page_projects_margin
            }
            this.loadProjectsMargin(this.query);
        },
        onPanUp() { this.openSearch = false },
        onPanDown() { this.openSearch = true },
        openProject(project) { this.projectSelected = project },
        loadProjects(query) {
            this.loading_projects = true
            if (query !== "") {
                this.isLoadingProject = true;
                this.axios({
                    method: "get",
                    url: "projects_management/project",
                    cancelToken: new CancelTokenProject(function executor(c) {
                        cancelProject = c;
                    }),
                    params: { ...query }
                }).then(response => {
                    if (this.page_projects === 1) { this.projects = response.data }
                    else {
                        this.projects.meta = response.data.meta
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.projects.data.push(response.data.data[index])
                        }
                    }
                    this.loading_projects = false
                }).catch(err => {
                    this.isLoadingProject = false;
                    this.resultsProject = err.message;
                })
            }
            else {
                this.clearProject()
            }
        },
        loadProjectsMargin(query) {
            this.loading_margin = true
            if (query !== "") {
                this.isLoading = true;
                this.axios({
                    method: "get",
                    url: "projects_management/project_margin",
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                    params: { ...query }
                }).then(response => {
                    if (this.page_projects_margin === 1) {
                        this.projects_margin = response.data
                        this.isLoading = false;
                    } else {
                        this.projects_margin.meta = response.data.meta
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.projects_margin.data.push(response.data.data[index])
                        }
                    }
                    this.loading_margin = false
                }).catch(err => {
                    this.isLoading = false;
                    this.results = err.message;
                })
            }
            else {
                this.clear()
            }
        },
        loadProjectsBilling() {
            this.loading_billing = true;
            this.axios.get('projects_management/project_billing',
                { params: {...this.call_params, page: this.page_projects_billing, alert_positive_or_negative: this.alertPositiveOrNegative, status:this.paramstatus} })
                .then(response => {
                    if (this.page_projects_billing === 1) {
                        this.projects_billing = response.data
                    }
                    else {
                        this.projects_billing.meta = response.data.meta
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.projects_billing.data.push(response.data.data[index])
                        }
                    }
                    this.loading_billing = false
                });
        },
        loadProjectsRevenueDays(query) {
            this.loading_revenue_days = true

            if (query !== "") {
                this.isLoadingRevenueDays = true;
                this.axios({
                    method: "get",
                    url: "projects_management/project_revenue_days",
                    cancelToken: new CancelTokenProjectsRevenue(function executor(c) {
                        cancelRevenueDays = c;
                    }),
                    params: { ...query }
                }).then(response => {
                    if (this.page_projects_revenue_days === 1) { this.projects_revenue_days = response.data }
                    else {
                        this.projects_revenue_days.meta = response.data.meta
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.projects_revenue_days.data.push(response.data.data[index])
                        }
                    }
                    this.loading_revenue_days = false
                }).catch(err => {
                    this.isLoadingRevenueDays = false;
                    this.resultsRevenueDays = err.message;
                })
            }
            else {
                this.clearProjectsRevenueDays()
            }
        },
        loadProjectsSales(query) {
            this.loading_sales = true

            if (query !== "") {
                this.isLoadingRevenueDays = true;
                this.axios({
                    method: "get",
                    url: "projects_management/project_sales",
                    cancelToken: new CancelTokenSales(function executor(c) {
                        cancelSales = c;
                    }),
                    params: { ...query }
                }).then(response => {
                    if (this.page_projects_sales === 1) { this.projects_sales = response.data }
                    else {
                        this.projects_sales.meta = response.data.meta
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.projects_sales.data.push(response.data.data[index])
                        }
                    }
                    this.loading_sales = false
                }).catch(err => {
                    this.isLoadingSales = false;
                    this.resultsSales = err.message;
                })
            }
            else {
                this.clearSales()
            }
        },
        loadProjectsFilters(query) {
            // this.loading = true
            if (query !== "") {
                this.isLoadingFilters = true;
                this.axios({
                    method: "get",
                    url: "projects_management/project_filters",
                    cancelToken: new CancelTokenFilters(function executor(c) {
                        cancelFilters = c;
                    }),
                    params: { ...query }
                }).then(response => {
                    this.projectStatusFilters = response.data.filters.status;
                    let obj = {
                        'filter_id': null,
                        'filter_name': 'Todos'
                    }
                    this.projectStatusFilters.unshift(obj);
                }).catch(err => {
                    this.isLoadingFilters = false;
                    this.resultsFilters = err.message;
                })
            }
            else {
                this.clearFilters()
            }
        },
        load() {
            if (this.alertPositiveOrNegative === 'negative' || this.alertPositiveOrNegative === null) {
                this.preApiProjectsCallRevenueDays();
            }
            this.preApiProjectsCallProject();
            this.preApiCall();
            // this.loadProjectsBilling();
            this.preApiProjectsSales();
            this.preApiProjectsFilters();
        },
        searchProject(data) {
            if (data) {
                return data.filter(post => {
                    return post['project_name'].toLowerCase().includes(this.searchInput.toLowerCase()) || post['client_name'].toLowerCase().includes(this.searchInput.toLowerCase())
                })
            }
            else { return [] }
        },
        setAlertPositiveOrNegative(){
            if(this.alertPositiveOrNegative==='negative'){
                this.alertPositiveOrNegative='positive'
            }else if(this.alertPositiveOrNegative==='positive'){
                this.alertPositiveOrNegative=null;
            }else{
                this.alertPositiveOrNegative='negative';
            }
        },
        resetPages(){
            this.page_projects = 1;
            this.page_projects_margin = 1;
            this.page_projects_billing = 1;
            this.page_projects_revenue_days = 1;
            this.page_projects_sales = 1;
        }
    },
    computed: {
        params() {
            if (this.$route.params.projectId) {
                return {...state.globalParams,...{idProject: this.$route.params.projectId}}
            }
            return state.globalParams
        },
        param_status() {
            if (this.projectStatusFiltersSelected && this.projectStatusFiltersSelected.filter_id) {
                return [this.projectStatusFiltersSelected.filter_id]
            }
            else { return null }
        },
        param_project_name() {
            if (this.searchInput !== '') { return this.searchInput }
            else { return null }
        },
        param_alert_positive_or_negative() {
            if (this.alertPositiveOrNegative !== '') { return this.alertPositiveOrNegative }
            else { return null }
        },
        call_params() {
            return {
                ...this.params,
                status: this.param_status,
                project_name: this.param_project_name,
                alert_positive_or_negative:this.param_alert_positive_or_negative
            }
        }
    },
    watch: {
        params(n, o) {
            this.projects_sales = false
            this.load();
        },
        page_projects(n,o) {
            if (n <= this.projects.meta.last_page) { this.preApiProjectsCallProject() }
        },
        page_projects_margin(n,o) {
            if (n <= this.projects_margin.meta.last_page) { this.preApiCall() }
        },
        page_projects_billing(n,o) {
            if (n <= this.projects_billing.meta.last_page) { this.loadProjectsBilling() }
        },
        page_projects_revenue_days(n,o) {
            if (n <= this.projects_revenue_days.meta.last_page) { this.preApiProjectsCallRevenueDays() }
        },
        page_projects_sales(n,o) {
            if (n <= this.projects_sales.meta.last_page) { this.preApiProjectsSales() }
        },
        param_project_name() { this.load() },
        param_alert_positive_or_negative() { this.load() },
        param_status() { 
            this.resetPages();
            this.load()
        }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}
</script>